.vvideoBase {
    position: relative;
    overflow: hidden;
}
  

@media (min-width: 1000px) {
    .videoBase {
        display: flex;
        align-items: center;
        justify-content: center;
    }


    button{
        width: 80%;
    }
}

@media (max-width: 1000px) {

   
}

.vvideo {
    width: 100%; /* Adjust the width as needed */
    height: auto; /* Automatically adjust the height to maintain aspect ratio */
    max-width: 1000px; /* Set a maximum width for the video container */
    max-height: 650px; /* Set a maximum height for the video container */
    min-height: 300px;
    border-radius: 15px;
    overflow: hidden;
  }

  

.vexampleBase{
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto; /* Center the .exampleBase container */
    background-color: #fff;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.snapText{
    text-align: center;
    font-size: 1.5rem;
    padding-left: 20px;
    padding-right: 20px;
}

button{
    background-color: #B75D69;
    color: white; /* Text color when not hovered */
    font-family: 'Source Code Pro', monospace;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease;
}

button:hover {
    background-color: rgb(62, 130, 36);
    color: black;
}

p{
    text-align: center;
    font-size: 1.5rem;
    font-family: 'Source Code Pro', monospace;
    font-weight: 700;
}


/* This is a comment in CSS */

.seP{
    color: #1B998B;
}

.sHero{
    color: #1B998B;
    font-family: 'Source Code Pro', monospace;
    font-weight: 700;
    font-size: 3rem;
}

@media (min-width: 700px) {
    .sHero {
        font-size: 2rem;
    }
    .vexampleBase{
        width: 60%;
    }
  }


  @media (max-width: 700px) {
    .sHero {
        font-size: 2rem;
    }
    .vexampleBase{
        width: 90%;
    }
  }