.vision-section {
    text-align: center;
    padding: 60px 20px; /* Adjust padding as needed */
  }
  
  .vision-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
  }
  
  .image-container {
    flex: 1;
    max-width: 20%; /* Adjust as needed */
    box-sizing: border-box;
  }
  
  .slnImage {
    width: 100%;
    height: auto;
  }
  
  .text-container {
    flex: 1;
    max-width: 80%; /* Adjust as needed */
    padding: 20px; /* Adjust as needed */
    text-align: center; /* Align text to the right */
    box-sizing: border-box;
  }
  
  h2 {
    font-size: 2em;
    margin-bottom: 15px;
    color: #fff;
  }
  
  p {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #fff;
  }
  

  
  @media (min-width: 1000px) {

  .vision-section {
    padding-left: 20%;
    padding-right: 20%;

   }
   .image-container {
      max-width: 10%; /* Adjust as needed */
  }

  .text-container{
    max-width: 40%; /* Adjust as needed */
  }
}