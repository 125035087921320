.card {
    flex-grow: 1;
    flex-basis: 25%;
    max-width: 30%;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }


.cardM{

  max-width: 90%;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  background-color: #ffffff;
}

  .cardTwo {
    flex-grow: 1;
    flex-basis: 25%;
    max-width: 30%;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background-color: #ffffff;
  }
  
  .card-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .card-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  
  .card-name {
    font-size: 2em;
    font-weight: bold;
    margin: 10px 0;
    font-family: 'Source Code Pro', monospace;
    font-weight: 700;
    color: #1B998B;
    text-align: center;
  }

  .card-description {
    font-size: 1.5em;
    margin: 10px 0;
    font-family: 'Source Code Pro', monospace;
    font-weight: 700;
    margin-bottom: 50;
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10%;
    padding-bottom: 10%;
  }
  
  .card-price {
    font-family: 'Source Code Pro', monospace;
    font-weight: 700;
    font-size: 1.5em;
    color: #1B998B;
    margin-bottom: 10px;
  }
  
  .card-button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.5em;
    margin: 4px 2px;
    cursor: pointer;
  }
  .content-container {
    flex-grow: 1; /* This makes the content container grow and push the button to the bottom */
}