.vision-sectionP {
    text-align: center;
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 40px;
    margin-left: 5%;
    margin-right: 5%;
    padding: 20px;
    flex-wrap: wrap;
  }
  
  .vision-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Allow items to wrap to the next line if needed */

  }
  
  .image-container {
    flex: 1;
    max-width: 20%; /* Adjust as needed */
    box-sizing: border-box;
    min-width: 100px; /* doesn't get smaller than 100px */
    height: auto; /* keeps the original aspect ratio */
    flex-wrap: wrap;
    
  }
  
  .slnImage {
    width: 100%;
    height: auto;

  }
  
  .text-container {
    flex: 1; /* Allow the text container to grow */
    min-width: 200px; /* Adjust as needed to control when text starts wrapping */

  
  }
  
  h2 {
    font-size: 2em;
    margin-bottom: 15px;
  }
  
  p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }

  .splitP{
    padding: 20px;
    color:aqua;
  }
  

  
  @media (max-width: 700px) {
    .vision-sectionP{ 
      width: 100%;
    }
  }

  @media (min-width: 700px) {
    .vision-sectionP {
      width: 100%;


      /* max-height: 50%; */
    }
  }
