.cardBlurb {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    text-align: center;
    border: 1px solid black;
    padding: 10px;
    width: 60%;
    margin: 0 auto;
    margin-top: 2vh;
    margin-bottom: 2vh;
}
.cardBlurbM {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    text-align: center;
    border: 1px solid black;
    padding: 10px;
    width: 100%;
    margin: 0 auto;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.blurb-video{
        width: 80%;
        /* max-height: "1VH"; */
}
.blurb-videoM{
    width: 100%;
    /* max-height: "1VH"; */
}

.blurb-text{
    font-family: 'Source Code Pro', monospace;
    font-weight: 700;
    font-size: 1.2em;
    margin-bottom: 20px;
    color:#000000;
}