.NavBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.NavBar-logo {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
}

.naavbarCustom-true {
    background-color: #ff0000;
    color: white;
    height: 100px;
}

.NavBar-links {
    display: flex;
    gap: 1rem;
}

.NavBar-link {
    font-size: 1rem;
    color: #b11515;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
}

.NavBar-link:hover {
    color: rgb(0, 116, 248);
}

.navbar-custom {
    background-color: #000000;
    color: white;
    height: 100px;
}

.navbar-brand-custom {
    color: #ffffff;
    font-family: 'Source Code Pro', monospace;
    font-weight: 600;
    font-size: 1.7rem;
}

.navbar-brand-customH {
    color: #ffffff;
    font-weight: 700;
    z-index: 1000;
    font-family: 'Source Code Pro', monospace;
    font-size: 2rem;

}

.navbar-brand-customH:hover {
    color: #ffffff;
}

.dropdown-open {
    margin-bottom: 100px; /* Adjust the margin value as needed */
}

.navbar-brand-custom:hover {
    color: #6EE043;
}

/* Add media query to adjust layout for screens less than 800px wide */
@media (max-width: 800px) {
    .NavBar {
        flex-direction: column;
        align-items: flex-start;
    }

    .NavBar-links {
        margin-top: 10px; /* Add spacing between brand and links in stacked layout */
    }

}


@media (max-width: 700px) {

    .navbar-brand-custom{
        font-size: 2rem;
    }

    .navbar-brand-customH{
        font-size: 1.6rem;
    }
}

.navMain {
    margin-top: 0px; /* Adjust this value based on your design */
  }

  .navbar-custom.expanded {
    margin-bottom: 70px; 
    background-color: #db0000;
    /* Adjust this value based on your Navbar's height when expanded */
  }


#basic-navbar-navY{
    background-color: #6EE043;
}