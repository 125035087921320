.pTitle{
    color: #1B998B;
    font-size: 30px;
    font-weight: 700;
    font-family: 'Source Code Pro', monospace;
    border-radius: 10px;
}

.pSubTitle{
    color: #000;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Source Code Pro', monospace;
    border-radius: 10px;
}

.pContentBase{
    background-color: #fff;
    border-radius: 50px;
    margin-top: 100px;
    margin-left: 7%;
    margin-right: 7%;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 1%;
    padding-bottom: 1%;


}

.pInstructions{
    color: #413b3b;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Source Code Pro', monospace;
    border-radius: 10px;
}

.pBase{
    margin-bottom: 2%;
}

.snapcode{
    width: 100px;
    margin-bottom: 20px;
}
