.vision-sectionAR {
  text-align: center;
  padding: 60px 20px; /* Adjust padding as needed */
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 40px;
  margin-left: auto; /* Center the section horizontally */
  margin-right: auto; /* Center the section horizontally */
  max-width: 80%; /* Prevent the section from getting too wide */
  box-sizing: border-box; /* Include padding and border in the width */
  overflow: auto; /* Add scroll if the content exceeds the max-height */
  margin-bottom: 10%;
}

.vision-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
}

.image-container {
  flex: 1;
  max-width: 40%; /* Adjust as needed */
  box-sizing: border-box; /* Include padding and border in the width */
}

.slnImage {
  width: 100%;
  height: auto;
}

.text-container {
  flex: 1;
  max-width: 80%; /* Adjust as needed */
  padding: 20px; /* Adjust as needed */
  text-align: center; /* Align text to the right */
  box-sizing: border-box; /* Include padding and border in the width */
}

.socialHeading {
  font-size: 2em;
  margin-bottom: 15px;
  color: #000000;
  font-family: 'Source Code Pro', monospace;
  font-weight: 700;
}

.socialText {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #000000;
}

@media (min-width: 700px) {
  .vision-sectionAR{
    width: 40%;
  }
}

@media (max-width: 700px) {
  .vision-sectionAR{ 
    width: 90%;
  }
}