.gvideoBase {
    position: relative;
    overflow: hidden;
    padding: 10px;
}
  

@media (min-width: 700px) {


    .gexampleBase{
        width: 17%;
    }

    .gHeroText{
        font-size: 1rem;
    }

}

@media (max-width: 700px) {

   .gexampleBase{
        width: 90%;
   }

   .gHeroText{
        font-size: 1.5rem;
   }

   
}
  .gimg {
    width: 100%; /* Adjust the width as needed */
    height: auto; /* Automatically adjust the height to maintain aspect ratio */
    max-width: 900px; /* Set a maximum width for the video container */
    max-height: 550px; /* Set a maximum height for the video container */
    border-radius: 15px;
    overflow: hidden;
  }


.gexampleBase{
    display: flex;
    flex-direction: column;
    margin: 0 auto; /* Center the .exampleBase container */
    background-color: #fff;
    padding-top: 1%;
    padding-bottom: 1%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin-bottom: 2%;
    margin-top: 2%;
}

.gText{
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;

}

.gHeroText{
    text-align: center;
    font-size: 1.5rem;
    padding-left: 20px;
    padding-right: 20px;

}

button{
    background-color: #B75D69;
    color: white; /* Text color when not hovered */
    font-family: 'Source Code Pro', monospace;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease;
}

button:hover {
    background-color: rgb(62, 130, 36);
    color: black;
}

p{
    text-align: center;
    font-size: 1.5rem;
    font-family: 'Source Code Pro', monospace;
    font-weight: 700;
}


/* This is a comment in CSS */

.seP{
    color: #1B998B;
}