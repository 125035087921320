.vision-sectionh {
    text-align: center;
    padding: 60px 20px; /* Adjust padding as needed */
    background-color: #374B51;
  }
  
  .vision-containerh {

  }
  
  .image-containerh {
    flex: 1;
    max-width: 50%; /* Adjust as needed */
    box-sizing: border-box;
  }
  
  .visionh-image {
    width: 100%;
    height: auto;
    animation: rotateAnimation 30s linear infinite;
  }
  
  .text-containerh {
    flex: 1;
    max-width: 50%; /* Adjust as needed */
    padding: 20px; /* Adjust as needed */
    color: #B75D69; /* Text color */
    text-align: center; /* Align text to the right */
    box-sizing: border-box;
  }
  
  .visionh2 {
    color: #ACFF9B;
    font-size: 2em;
    margin-bottom: 15px;

  }
  
  .visionp {
    font-family: 'Source Code Pro', monospace;
    font-weight: 700;
    font-size: 1.5em;
    margin-bottom: 20px;
    color:#fff;
  }
  
  .cta-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #B75D69; /* Button color */
    color: #fff; /* Text color */
    text-decoration: none;
    border-radius: 5px;
    font-family: 'Source Code Pro', monospace;
    font-weight: 700;
  }
  
  @keyframes rotateAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


  @media (max-width: 700px) {
    .vision-sectionh{
      
    }
    .text-containerh{
      max-width: 100%;
    }

    .image-containerh {
      max-width: 50%; /* Adjust as needed */
      box-sizing: border-box;
      margin: auto;
    }

    p{
      font-size: 1em;
    }

    .cta-button {
      font-size: 20px;
    }



  }

  @media (min-width: 700px) {
    .vision-containerh {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
    }

    .cta-button {
      font-size: 20px;
    }
    
  }